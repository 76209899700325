<template>
  <div class="login_input err_message_box">
    <span class="err_message txt-red small">{{errorMsg}}</span>
    <h4 class="txt-dark_green txt-bold">{{ item.name }}</h4>
    <label for="login_password">
      <input
        :id="id"
        :value="value"
        :rules="rules"
        :type="item.type"
        :error="error"
        :maxlength="maxlength"
        :placeholder="item.placeholder"
        @blur="test()"
        @input="$emit('input', $event.target.value)"
      >
    </label>
    <img
      v-if="item.name === '密碼'"
      class="password_eye"
      src="@/statics/img/icon/remove_red_eye-24px.svg"
      alt=""
      @click="toggleInputType"
    >
  </div>
</template>

<script>
export default {
  name: 'FormLogin',
  props: {
    value: {},
    rules: Array,
    maxlength: Number,
    item: {},
  },
  data() {
    return {
      id: null,
      errorMsg: '',
    };
  },
  watch: {
    value(v) {
      this.errorMsg = this.validate(v) ? this.validate(v) : '';
    },
  },
  computed: {
    hasRules() {
      return this.rules !== undefined
        && this.rules !== null
        && this.rules.length > 0;
    },
    error() {
      if (this.hasRules && this.errorMsg !== '') {
        return true;
      }
      return false;
    },
  },
  methods: {
    toggleInputType() {
      this.$emit('toggleInputType');
    },
    test() {
      this.errorMsg = this.validate(this.value) ? this.validate(this.value) : '';
    },
    validate(val = this.value) {
      if (this.hasRules === true) {
        const errorMessage = [];
        this.rules.forEach((rule) => {
          if (typeof rule === 'function') {
            if (rule(val) !== true) {
              errorMessage.push(rule(val));
            }
          } else if (typeof rule === 'object') {
            if (!val.match(rule[0])) {
              errorMessage.push(rule[1]);
            }
          }
        });
        if (errorMessage.length) {
          return errorMessage[0];
        }
      }
      return '';
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.id = this._uid;
  },
};
</script>
